import React, { FC, useEffect, useState } from "react";
import classNames from "classnames/dedupe";
import { cn } from "src/helpers/bem";
import { Props } from "./props";
import "./Signing.scss";
import { FileView, SigningData } from "./components";
import { Divider, message, Skeleton } from "antd";
import { useParams, useLocation } from "react-router-dom";
import {
  SelfSigningItem,
  useGetSelfSigningApplicantInfoQuery,
  useGetSelfSigningFilesListQuery,
  useSelfSignDocumentsMutation,
} from "src/redux/selfSigning";
import qs from "query-string";
import { RetryBlock } from "src/components";
import { CertificateInfoInterface } from "ruscryptojs";
import { useInitCryptoPro } from "src/hooks/useInitCryptoPro";
import { showLoadingMessage } from "src/helpers";
import { baseURL } from "src/constants";
import { ReactComponent as CheckIcon } from "../images/check-icon.svg";
import { SigningDataViewMode } from "./components/SigningData/props";
import { Helmet } from "react-helmet";
import { useHideElement } from "../../hooks/useHideElement";

const b = cn("signing");
const sf = cn("site-forms");

type CertificateInfo = CertificateInfoInterface & { id: string };

export const Signing: FC<Props> = (props) => {
  const { className } = props;
  const { token } = useParams<{ token: string }>();

  const location = useLocation();
  const entryId = qs.parse(location.search).entryId as string;
  const [certificates, setCertificates] = useState<CertificateInfo[]>([]);
  const [selectedCertificate, setSelectedCertificate] = useState<CertificateInfo | null>(null);
  const { cryptoproRef } = useInitCryptoPro();

  const [selfSignDocuments] = useSelfSignDocumentsMutation();

  const {
    currentData: filesList,
    isLoading: filesListLoading,
    isError: filesListLoadingError,
    refetch: refetchFilesList,
  } = useGetSelfSigningFilesListQuery({
    token,
    entryId: entryId as string,
  });

  const [signingSuccess, setSigningSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (filesList) {
      setSigningSuccess(filesList.isSigned);
    }
  }, [filesList, filesList?.isSigned]);

  useHideElement({ querySelector: ".carrotquest-messenger-right_bottom" });

  const {
    currentData: applicantInfo,
    isLoading: applicantInfoLoading,
    isError: applicantInfoLoadingError,
    refetch: refetchApplicantInfo,
  } = useGetSelfSigningApplicantInfoQuery({
    token,
    entryId: entryId as string,
  });

  async function handleGetCertificatesClick() {
    try {
      showLoadingMessage("Идет поиск сертификатов на устройстве");
      const certificatesList = await cryptoproRef.current.listCertificates();
      const certificatesDetailedInfoPromises = [];
      for (let i = 0; i < certificatesList.length; i++) {
        certificatesDetailedInfoPromises.push(cryptoproRef.current.certificateInfo(certificatesList[i].id));
      }

      const certificatesDetailedInfo = (await Promise.all(certificatesDetailedInfoPromises)).map((it, index) => ({
        ...it,
        id: certificatesList[index].id,
      }));

      setCertificates(certificatesDetailedInfo);
      message.destroy();
    } catch (e) {
      message.destroy();
      message.error(
        `Ошибка при получении списка сертификатов. Проверьте, установлен ли плагин CryptoPro: ${JSON.stringify(e)}`
      );
    }
  }

  async function handleCertificateClick(certificateData: CertificateInfo) {
    setSelectedCertificate(certificateData);
  }

  function getDownloadFileLink({ fileName }: { fileName: string }) {
    return `${baseURL}/api/v1/selfsign/get_file?id=${entryId}&token=${token}&fileId=${fileName}`;
  }

  const toDataURL = (url: string) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  async function signFile({ fileNameToSign, certificate }: { fileNameToSign: string; certificate: CertificateInfo }) {
    try {
      let fileAsBase64: string = (await toDataURL(getDownloadFileLink({ fileName: fileNameToSign }))) as any;
      // fileAsBase64 = fileAsBase64.replace("data:image/tiff;base64,", "");
      // fileAsBase64 = fileAsBase64.replace("data:application/xml;base64,", "");
      // разбиваю base64 по запятой, чтобы убрать говно вида "data:image/tiff;base64"
      fileAsBase64 = fileAsBase64.split(",")[1];
      if (fileAsBase64) {
        const signRes = await cryptoproRef.current.signData(fileAsBase64, certificate.id);
        try {
          await cryptoproRef.current.verifySign(fileAsBase64, signRes);
        } catch (e) {
          throw "Ошибка проверки подписи";
        }
        const res: SelfSigningItem = {
          fileName: fileNameToSign,
          sign: signRes,
        };
        return res;
      }
    } catch (e) {
      throw `Ошибка при подписании файла ${fileNameToSign}: ${JSON.stringify(e)}`;
    }
  }

  async function handleSignClick() {
    showLoadingMessage("Происходит подписание");
    const documentsSignPromises = [];
    try {
      for (let documentIndex = 0; documentIndex < filesList!.files.length; documentIndex++) {
        documentsSignPromises.push(
          await signFile({
            fileNameToSign: filesList!.files[documentIndex].fileId,
            certificate: selectedCertificate!,
          })
        );
      }
      const signingRes = await Promise.all(documentsSignPromises);
      const res: any = await selfSignDocuments({
        files: signingRes as SelfSigningItem[],
        entryId,
        token,
      });
      if (res.error) {
        throw res.error.data;
      } else {
        setSigningSuccess(true);
        message.destroy();
      }
    } catch (e) {
      message.destroy();
      message.error(`Ошибка при подписании: ${JSON.stringify(e)}`);
    }
  }

  function renderDocuments() {
    if (filesListLoadingError) {
      return <RetryBlock centered retryCallback={refetchFilesList} text="Не удалось загрузить документы" />;
    }

    return (
      <>
        <h2 className={classNames(sf("title"), b("title"))}>Документы</h2>
        <div className={sf("text")}>Проверьте пакет документов для подписания</div>
        <div className={b("files-list")}>
          {filesList && !filesListLoading
            ? filesList.files.map((fileData) => (
                <FileView
                  key={fileData.fileId}
                  fileName={fileData.fileId}
                  downloadLink={getDownloadFileLink({
                    fileName: fileData.fileId,
                  })}
                />
              ))
            : [1, 2, 3].map(() => <Skeleton.Button active block size="large" />)}
        </div>
        {signingSuccess && (
          <>
            <Divider />
            <div className={b("result")}>
              <CheckIcon className={b("result-icon")} />
              <div className={b("result-label")}>Пакет документов подписан</div>
            </div>
          </>
        )}
      </>
    );
  }

  function renderSignature() {
    return (
      <>
        <h2 className={classNames(sf("title"), b("title"))}>Электронная подпись</h2>
        {certificates.length ? (
          <div className={b("certs")}>
            {certificates.map((it) => {
              const hasInnle = Boolean(it.Subject.INNLE);
              const inn = (hasInnle ? it.Subject.INNLE : it.Subject.INN) || "";
              const alternativeFIO = `${it.Subject.SN || ""} ${it.Subject.G || ""}`;
              const fio = (hasInnle ? alternativeFIO : "") || "";
              const issuer = it.Issuer.CN;
              const serialNumber = it.SerialNumber;
              const oooName = hasInnle ? it.Subject.CN : alternativeFIO;
              return (
                <SigningData
                  showFIO={hasInnle}
                  mode={SigningDataViewMode.CERTIFICATE}
                  inn={inn}
                  fio={fio}
                  oooName={oooName}
                  issuer={issuer}
                  serialNumber={serialNumber}
                  onClick={() => handleCertificateClick(it)}
                  key={it.SerialNumber}
                  className={b("cert", {
                    selected: it.SerialNumber === selectedCertificate?.SerialNumber,
                  })}
                />
              );
            })}
          </div>
        ) : (
          <label
            onClick={handleGetCertificatesClick}
            className="custom-button custom-button_long custom-button_large custom-button_faded"
          >
            Выбрать электронную подпись
          </label>
        )}

        {selectedCertificate && Boolean(filesList?.files.length) && !signingSuccess && (
          <label
            className={classNames(b("sign-btn"), "custom-button", "custom-button_long", "custom-button_large")}
            onClick={handleSignClick}
          >
            Подписать пакет документов
          </label>
        )}
      </>
    );
  }

  function renderApplicant() {
    if (applicantInfoLoadingError) {
      return (
        <RetryBlock centered retryCallback={refetchApplicantInfo} text="Не удалось загрузить информацию о заявителе" />
      );
    }

    return (
      <>
        <h2 className={classNames(sf("title"), b("title"))}>Заявитель</h2>
        <div className={sf("text")}>Документы должны быть подписаны следующим заявителем</div>
        {applicantInfo && !applicantInfoLoading ? (
          <SigningData
            mode={SigningDataViewMode.APPLICANT}
            inn={applicantInfo.inn}
            fio={[applicantInfo.lastName, applicantInfo.firstName, applicantInfo.middleName]
              .filter((it) => Boolean(it))
              .join(" ")}
            oooName={applicantInfo.shortOOO}
          />
        ) : (
          <Skeleton active />
        )}
      </>
    );
  }

  return (
    <div className={classNames(b(), className)}>
      <Helmet>
        <title>Подписание документов</title>
      </Helmet>
      <div className={classNames(b("inner"), sf())}>
        {renderDocuments()}
        {!signingSuccess && (
          <>
            <Divider />
            {renderApplicant()}
            <Divider />
            {renderSignature()}
          </>
        )}
      </div>
    </div>
  );
};
